import * as React from 'react';
// Styles
import './SectionWrapper.scss';

const SectionWrapper = ( { sectionWrapperData, children, className, ...otherProperties } ) => {
    return (
        <div
            className={ `general-section-wrapper ${ className || '' }` }
            { ...otherProperties }
        >
            { children }
        </div>
    );
};

export default SectionWrapper;
