import React, {useEffect} from "react"
import { navigate } from "gatsby"

// Components
import Layout from "../components/layout"
import LoginScreen from "layout/Login/LoginScreen"
import {SEODetails} from "../components/common/static_seo"
import {CustomLinks} from "../components/common/menuUtils"

import { isLoggedIn } from "../services/auth"

const Login = () => {
    const [isAuth, setIsAuth] = React.useState(isLoggedIn())

    useEffect(() => {
        if(isAuth){
            navigate('/'+CustomLinks.dashboard+'/')
        } else {
            navigate('/'+CustomLinks.login+'/')
        }
    },[isAuth]);

    return (
        <Layout variant={""} Meta_Title={SEODetails.login.title} Meta_Description={SEODetails.login.description}>
            <LoginScreen  />
        </Layout>
    )
}

export default Login